.text-underline {
  text-decoration: underline !important;
  margin-right: 20px !important;
}

ul {
  list-style-type: none;
  padding-left: 0px;
}

footer {
  background-color: #294c7c;
  color: #fff;
  position: relative;
}

footer .height-24 {
  height: 24px;
}

.footer-title {
  color: rgb(255, 255, 255) !important;
  font-size: 19px;
  text-align: center;
  font-family: GlacialInDifference !important;
  margin-bottom: 0.5em;
  font-weight: unset !important;
}

footer .start-quote-container {
  margin-left: 15%;
  width: 848px;
}

.footer-paper-peel {
  background-image: url('https://assets.cure.com/img/main-site/footer-page-peel.png');
  width: 91.2px;
  height: 105px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.text-reset {
  color: rgb(255, 255, 255) !important;
  line-height: 1.5em;
  font-size: large !important;
  font-family: GlacialInDifference !important;
}

.fnol-online {
  color: #fff!important;
  text-decoration: underline;
}

a.social {
  margin-right: 8px;
  transition: transform 250ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-2px);
}

a.youtube {
  color: #fff;
}

a.facebook {
  color: #fff;
}

a.twitter {
  color: #fff;
}

a.instagram {
  color: #fff;
}

.twitter img {
  content: url('https://assets.cure.com/img/main-site/webp/x-logo.webp');
  width: 30px;
  height: 30px;
  display: inherit;
}

.facebook img {
  content: url('https://assets.cure.com/img/main-site/webp/facebook.webp');
  width: 30px;
  height: 30px;
  display: inherit;
}

.youtube img {
  content: url('https://assets.cure.com/img/main-site/webp/youtube.webp');
  width: 30px;
  height: 30px;
  display: inherit;
}

.instagram img {
  content: url('https://assets.cure.com/img/main-site/webp/instagram.webp');
  width: 30px;
  height: 30px;
  display: inherit;
}

.linkedin img {
  content: url('https://assets.cure.com/img/main-site/webp/linkedin.webp');
  width: 30px;
  height: 30px;
  display: inherit;
}

a {
  text-decoration: none;
}

.text-menu {
  color: rgb(255, 255, 255) !important;
  line-height: 1.7em;
  font-size: large !important;
  font-family: GlacialInDifference !important;
}

.mobile-footer {
  display: none !important;
}

.medium-footer {
  display: none !important;
}

.footer-address {
  text-align: center;
  text-transform: capitalize;
}

.copyright {
  font-size: 19px;
  text-align: center;
  font-family: GlacialInDifference !important;
}

footer .last-btn {
  margin-left: 0 !important;
}

footer .footer-btn-roadside-mobile {
  margin-left: 2px !important;
}

.margin-top-18 {
  margin-top: 18px;
}

.start-quot-coloumn {
  max-width: 282px !important;
}

.log-in-coloumn {
  max-width: 282px !important;
}

.why-cure-coloumn {
  max-width: 282px !important;
}

.footer-below-address-row {
  margin-bottom: 12px !important;
  width: max-content;
}

footer .footer-mobile-margin-right {
  margin-right: 0px !important;
}
/* 
.mobile-feedback {
  display: none;
} */

@media (max-width: 1463px) {
  footer .start-quote-container {
    margin-left: 20%;
  }
}

@media (max-width: 1452px) {
  footer .start-quote-container {
    margin-left: 20%;
  }
}

@media (max-width: 1377px) {
  footer .start-quote-container {
    margin-left: 16%;
  }
}

@media (max-width: 1315px) {
  footer .start-quote-container {
    margin-left: 12%;
  }
}

@media (max-width: 1259px) {
  footer .start-quote-container {
    margin-left: 10%;
  }
}

@media (max-width: 1233px) {
  footer .start-quote-container {
    margin-left: 0%;
    left: 4%;
  }
  footer .start-quote-container {
    width: 810px;
  }
}

@media (max-width: 1099px) {
  footer .start-quote-container {
    left: 5%;
  }
  footer .start-quote-container {
    width: 800px;
  }
}

@media (max-width: 1069px) {
  footer .start-quote-container {
    left: 3%;
  }
  footer .start-quote-container {
    width: 780px;
  }
}

@media (max-width: 1049px) {
  footer .start-quote-container {
    left: 0%;
  }
  footer .start-quote-container {
    width: 750px;
    margin-left: 24px;
  }
}

@media (max-width: 1049px) {
  footer .start-quote-container {
    width: 750px;
    margin-left: 18px;
  }
}

@media (max-width: 1027px) {
  footer .start-quote-container {
    width: 688px;
    margin-left: 32px;
  }
}

@media (max-width: 1037px) {
  footer .start-quote-container {
    width: 688px;
    margin-left: 22px;
  }
}

@media (max-width: 969px) {
  footer .start-quote-container {
    width: 658px;
    margin-left: 28px;
  }
}

@media (max-width: 945px) {
  footer .start-quote-container {
    width: 628px;
    margin-left: 12px;
  }
}

@media (max-width: 934px) {
  footer .start-quote-container {
    width: 600px;
    margin-left: 12px;
  }
}

@media (max-width: 872px) {
  footer .start-quote-container {
    width: 600px;
    margin-left: 0px;
  }
}

@media (max-width: 481px) {
  .mobile-footer {
    display: block !important;
  }

  .desktop-footer {
    display: none !important;
  }

  .medium-footer {
    display: none !important;
  }

  .footer-title {
    font-size: small !important;
    margin-left: 0px;
    display: block;
  }

  .text-underline {
    display: inline-block;
  }

  .text-reset {
    font-size: small !important;
  }

  .text-menu {
    font-size: small !important;
  }

  .footer-address {
    text-align: center;
    font-size: small !important;
    text-transform: capitalize;
  }

  .copyright {
    font-size: small !important;
    text-align: center;
    margin-bottom: 10px;
  }

  .social-container {
    margin-left: 5px;
  }

  ul {
    padding-left: 5px;
    width: 100%;
  }

  .text-menu {
    font-size: small !important;
  }

  footer {
    padding: 20px 5px;
  }

  .footer-paper-peel {
    width: 48.2px;
    height: 62px;
  }

  footer .mobile span {
    font-size: small !important;
  }

  footer .footer-btn,
  footer .footer-btn:active,
  footer .footer-btn:focus,
  footer .footer-btn a {
    background: #ff8000 !important;
    border: none;
    color: #fff !important;
    padding: 3px;
    font-size: 12px;
    width: auto;
    width: 116px;
  }

  footer .footer-btn-roadside,
  footer .footer-btn-roadside:focus,
  footer .footer-btn-roadside:active,
  footer .footer-btn-roadside a {
    background: #ff8000 !important;
    border: none;
    color: #fff !important;
    padding: 4px 0px;
    font-size: 12px;
    width: auto;
    width: 116px;
  }

  footer .phone {
    margin-right: 2px;
  }

  footer .footer-btn-roadside-mobile {
    margin-left: 0px !important;
  }
  a.social {
    margin-right: 24px;
  }
  .mobile-feedback {
    display: block;
  }
  .mobile-feedback span {
    font-family: 'GlacialIndifference';
    color: #294c7c;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .mobile-footer {
    display: none !important;
  }

  .medium-footer {
    display: block !important;
  }

  .desktop-footer {
    display: none !important;
  }

  footer {
    padding: 12px;
  }

  .footer-paper-peel {
    width: 48.2px;
    height: 62px;
  }

  .footer-title {
    font-size: large !important;
  }

  .footer-address {
    text-align: center;
    font-size: 20px !important;
    text-transform: capitalize;
  }

  .copyright {
    font-size: 20px !important;
    text-align: center;
    margin-bottom: 20px;
  }

  .text-reset {
    font-size: large !important;
  }

  .text-menu {
    font-size: large !important;
  }

  ul li {
    width: 100%;
  }

  .twitter img {
    width: 30px;
    height: 30px;
  }

  .facebook img {
    width: 30px;
    height: 30px;
  }

  .youtube img {
    width: 30px;
    height: 30px;
  }

  .instagram img {
    width: 30px;
    height: 30px;
  }

  .linkedin img {
    width: 30px;
    height: 30px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .medium-footer {
    display: none !important;
  }

  .mobile-footer {
    display: none !important;
  }

  .footer-title {
    font-size: 19px !important;
    margin-left: 20px;
  }

  .text-underline {
    font-size: medium !important;
    margin-right: 12.5px !important;
  }

  .text-reset {
    font-size: medium !important;
  }

  .text-menu {
    font-size: medium !important;
  }

  .copyright {
    font-size: 19px;
    text-align: center;
    margin-bottom: 10px;
  }

  .social-container {
    margin-left: 0px !important;
  }

  .desktop-footer ul {
    padding-left: 0px !important;
  }

  a.social {
    margin-right: 8px;
  }

  .twitter img {
    width: 28px;
    height: 28px;
  }

  .facebook img {
    width: 28px;
    height: 28px;
  }

  .youtube img {
    width: 28px;
    height: 28px;
  }

  .instagram img {
    width: 28px;
    height: 28px;
  }

  .linkedin img {
    width: 28px;
    height: 28px;
  }

  .start-quot-coloumn {
    max-width: 282px !important;
  }

  .log-in-coloumn {
    max-width: 282px !important;
  }

  .why-cure-coloumn {
    max-width: 282px !important;
  }

  .address-coloumn {
    margin-left: 0px !important;
  }

  .footer-below-address-row {
    margin-bottom: 12px !important;
    /* min-width: 674px !important; */
  }
}

@media (max-width: 360px) {
  .mobile-footer {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  footer .footer-btn,
  footer .footer-btn:active,
  footer .footer-btn:focus,
  footer .footer-btn a {
    background: #ff8000 !important;
    border: none;
    color: #fff !important;
    padding: 2px;
    font-size: 12px;
    width: auto;
    width: 116px;
  }

  footer .last-btn {
    width: 100px !important;
  }

  .mobile-footer .first-coloumn-below-buttons {
    margin-right: 40px !important;
  }

  .mobile-footer .second-coloumn-below-buttons {
    margin-right: 40px !important;
  }
}
