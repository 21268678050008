body {
    margin: 0;
    padding: 0;
    font-family: Lato, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.bg-primary2 {
    background-color: #284c7c;
}

.bg-secondary {
    background-color: #366898;
}

.bg-muted {
    background-color: #F1F1F1;
}

.bg-alert {
    background-color: #f2dede;
}

.bg-success {
    background-color: #dff0d8;
}

.text-primary {
    color: #046ab0;
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #005595;
    border-color: #005595;

}

.btn-outline-primary {
    color: #005595;
    border-color: #005595;
    border-width: 1px;

}

.border-alert {
    border-color: #eed3d7;
}

.border-primary {
    border-color: #005595;
}

.border-success {
    border-color: #d6e9c6;
}

/* .text-muted {
    color: #555;
  } */

.text-primary {
    color: #046ab0;
    ;
}

.text-alert {
    color: #b94a48;
}

.text-success {
    color: #468847;
}

.ms-6 {
    margin-left: 4em;
}

@font-face {
    font-family: "GlacialIndifference";
    src: url("../assets/fonts/glacial-indifference/GlacialIndifference-Regular.otf");
    display: swap;
}

@font-face {
    font-family: "GlacialIndifference";
    src: url("../assets/fonts/glacial-indifference/GlacialIndifference-Bold.otf");
    font-weight: bold;
    display: swap;
}

@font-face {
    font-family: "GlacialIndifference";
    src: url("../assets/fonts/glacial-indifference/GlacialIndifference-Italic.otf");
    font-style: italic;
    display: swap;
}

@font-face {
    font-family: "Monsterrat";
    src: url("../assets/fonts/montserrat/Montserrat-Regular.ttf");
    display: swap;
}

.font-monsterrat {
    font-family: 'Monsterrat';
}

@font-face {
    font-family: "Monsterrat";
    src: url("../assets/fonts/montserrat/Montserrat-Bold.ttf");
    font-weight: bold;
    display: swap;
}

@font-face {
    font-family: "Monsterrat";
    src: url("../assets/fonts/montserrat/Montserrat-Italic.ttf");
    font-style: italic;
    display: swap;
}

@font-face {
    font-family: "Gotham";
    src: url("../assets/fonts/gotham/Gotham-Bold.otf");
    font-weight: bold;
    display: swap;
}

@font-face {
    font-family: "Lato";
    src: url("../assets/fonts/lato/Lato-Regular.ttf");
    display: swap;
}

@font-face {
    font-family: "Lato";
    src: url("../assets/fonts/lato/Lato-Bold.ttf");
    font-weight: bold;
    display: swap;
}

@font-face {
    font-family: "Lato";
    src: url("../assets/fonts/lato/Lato-Italic.ttf");
    font-style: italic;
    display: swap;
}

.img-logo {
    /* height: 50px; */
    width: 50px;
}


@media (max-width: 575px) {
    .header-font {
        font-size: .7em;
    }

    .react-datepicker__close-icon {
        position: relative;
        margin-left: 1em;
    }

    .expandHeight {
        margin-top: 1.5em;
    }

    .summaryDriver {
        margin-top: 1em;
        margin-bottom: 1em;
    }

    /* .lowerFont {
      font-size: 9px;
    } */
}

@media (max-width: 500px) {
    .expandHeight {
        margin-top: 2em;
    }

    .react-datepicker__close-icon {
        position: relative;
        margin-left: 1em;
    }

    .expandHeight {
        margin-top: 1.5em;
    }

    /* .lowerFont {
      font-size: 9px;
    } */
}

@media (max-width: 500px) {
    .expandHeight {
        margin-top: 2em;
    }
}

@media (min-width: 576px) {
    .banner-font {
        font-size: .9em;
        letter-spacing: 1px;

    }

    .header-font {
        font-size: 1.1em;
    }

    .footer-text {
        font-size: .6em;
    }

    .menu-font {
        font-size: 25px;

    }
}

@media (min-width: 768px) {
    .menu-font {
        font-size: 40px;

    }

    .banner-font {
        font-size: 1.0em;
        letter-spacing: 3px;

    }

}

@media (min-width: 992px) {
    .banner-font {
        font-size: 1.2em;
    }

    .menu-font {
        font-size: 50px;
    }

    .footer-text {
        font-size: .8em;
    }

}

@media (min-width: 1200px) {
    .menu-font {
        font-size: 55px;
    }

    .footer-text {
        font-size: 1em;
    }

}

.btn.btn-primary-grad {
    position: relative;
    border: none;
    background: linear-gradient(to bottom, #358cc6 0, #07649e 100%);
    color: #fff;
    text-shadow: 0 0 4px #036;
}

.btn.btn-primary {
    background: #1B7BC0;
}

.btn.btn-primary:hover {
    background: #3097df;
}

a {
    color: #0a67a9;
    cursor: pointer;
    text-decoration: none;
}

a.text-primary:hover {
    color: #a9cae1;
}

.btn.btn-secondary {
    background-image: linear-gradient(#fff, #fff 60%, #f5f5f5);
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    color: #0a67a9;

}


.menu-button {
    font-size: 13px;
    color: #555555;
}

.banner-font {
    font-family: 'GlacialIndifference';
    color: white;
}

.footer-text {
    font-family: 'GlacialIndifference';
}

.header-font {
    font-family: 'GlacialIndifference';
    color: white;
    letter-spacing: 3px;
}

.menu-button {
    height: 5px;
}

.menu-font {
    position: relative;
    z-index: 5;
    color: #046ab0;
    line-height: 50px;
    font-weight: bold;
    font-family: Gotham, Arial, Helvetica, sans-serif;
    letter-spacing: -3px;
    margin: 0;
    padding: 0;
    text-shadow: 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 4px #111, 0 0 4px #111;
}

.section-title {
    font-family: Gotham, Arial, Helvetica, sans-serif;
    color: #046ab0;
}

.section-subtitle {
    font-family: Gotham, Arial, Helvetica, sans-serif;
    color: #bbbbbb;
}

.payment-table th {
    color: #046ab0;
    font-size: 16px;
    line-height: 20px;
    text-transform: none;
    padding: 0 0 7px;
    font-family: Gotham, Arial, Helvetica, sans-serif;
}

.payment-table td {
    /* font-weight: 400; */
    color: #6fb5e2;
    text-transform: none;
    font-size: 16.8;
    font-family: Gotham, Arial, Helvetica, sans-serif;

}

.payment-message {
    font-size: 14px;
    font-family: Lato, sans-serif;
    color: #013cb5;
    line-height: 2;
}

.policy-table th {
    color: #046ab0;
    font-weight: 400;
}



.grad-window {
    border: 1px solid #9dc6e1;
    border-radius: 6px;
    box-shadow: 0 0 6px #929292;
    /* background-color: #f4fafe; */
    /* background: linear-gradient(top,#2288cc,#000000 100%); */
    background: linear-gradient(#f4fafe 0, #dcecf6 100%);
    /* background: linear-gradient(to bottom, #358cc6 0, #07649e 100%); */

}

.text-right-md {
    text-align: right
}

.head h1 {
    position: relative;
    z-index: 5;
    color: #046ab0;
    font-size: 55px;
    font-weight: 600;
    font-family: Gotham-Bold, Arial, Helvetica, sans-serif;
    letter-spacing: -3px;
    margin: 0;
    padding: 0;
    text-shadow: 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 2px #fff, 0 0 4px #111, 0 0 4px #111;
}

.react-datepicker__year-dropdown .react-datepicker__year-option:first-child::before {
    content: "\25B2";
    position: absolute;
    top: 5%;
    left: 45%;
    margin: auto;
}

.react-datepicker__year-dropdown::after {
    content: "\25BC";
    display: block;
    position: relative;
    bottom: 15%;
}