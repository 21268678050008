.contact-hero-row {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
  height: 360px;
  background: url('https://assets.cure.com/img/main-site/contact.jpeg');
  background-size: cover;
}

.contact-hero-container {
  width: 1140px;
  margin: auto;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
}

.contact-title {
  display: inline-block;
  margin-bottom: 0px !important;
  margin-left: 0px !important;
  font-size: 40px;
  color: white !important;
  line-height: 1.12em;
  letter-spacing: 0em;
  --para-spacing: 0;
  text-transform: none;
  --head-indent: 0;
  --numeric-list-marker: none;
  list-style-type: none;
  width: inherit;
  padding: 0;
  font-family: raleway;
  font-weight: 400 !important;
}

.hours-text {
  color: #1d4c80 !important;
  font-family: 'GlacialInDifferenceBold' !important;
}

.contact-container .start-quote,
.contact-container .start-quote:hover {
  width: 184px;
  background: #ff8000;
  border: none;
  color: #fff;
  height: 40px;
  border-radius: 12px;
}

.contact-container .margin-bottom-4 {
  margin-bottom: 4px;
}

.contact-container .margin-bottom-6 {
  margin-bottom: 6px;
}
.contact-container .margin-top-6 {
  margin-top: 6px;
}

.contact-container .online-fnol {
  margin-right: 0;
  background: #ff8000;
  color: #fff;
  font-family: GlacialIndifference;
  padding-top: 8px!important;
  line-height: 23px;
  position: relative;
  display: inline-block;
  font-weight: bold;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  box-shadow: 0 2px 0 rgba(0,0,0,.015);
  cursor: pointer;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  touch-action: manipulation;
  height: 40px;
  padding: 4px 15px;
  font-size: 16px;
  border-radius: 2px;
  color: rgba(0,0,0,.85);
  border: 1px solid #d9d9d9;
  color: #fff!important;
  border-radius: 49px;
}

.contact-container .margin-top-4 {
  margin-top: 4px;
}
.contact-container .margin-top-8 {
  margin-top: 8px;
}

.contact-container .margin-bottom-8 {
  margin-bottom: 0px;
}

.contact-container .margin-bottom-12 {
  margin-bottom: 12px !important;
}

.contact-container .margin-top-52 {
  margin-top: 52px;
}

.contact-container .margin-left-68 {
  margin-left: 68px;
}

.contact-container .customer-relations-card .ant-card-body {
  padding-left: 0px;
}

.contact-container .contact-sub-title {
  font-weight: bold !important;
  font-size: 23px !important;
  font-style: normal;
  color: #1d4c80 !important;
  text-decoration: none;
  font-family: 'GlacialInDifference' !important;
}

.contact-container ul li {
  color: #000 !important;
}

button:disabled {
  opacity: 70%;
  cursor: not-allowed;
}

.chatForm {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.chatForm input, .chatForm textarea {
  background-color: rgb(248, 248, 248);
  border: 1px solid rgb(117, 117, 117);
  border-radius: 6px;
  margin-bottom: 10px;
}

.chatForm select {
  background-color: rgb(248, 248, 248);
  border: 1px solid rgb(117, 117, 117);
  border-radius: 6px;
  padding: 5px;
  margin-bottom: 10px;
}

.chatForm p {
  color: rgb(17, 17, 17);
  opacity: 0.8;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.contact-container .sub-text {
  font-weight: 400;
  font-style: normal;
  color: rgb(0, 0, 0) !important;
  text-decoration: none;
  color: rgb(0, 0, 0);
  line-height: 1.58em;
  letter-spacing: 0em;
  --para-spacing: 0;
  text-transform: none;
  --head-indent: 0;
  --numeric-list-marker: none;
  list-style-type: none;
  font-size: 21px;
  font-family: GlacialInDifference;
  display: inline-block;
}

.contact-container .sub-text-bold {
  font-weight: 700;
  font-style: normal;
  color: rgb(29, 76, 128) !important;
  text-decoration: none;
  margin: 12px 0px;
  font-family: 'GlacialInDifference' !important;
  font-size: 22px;
  line-height: 28px;
}

.contact-container .width-100 {
  width: 100%;
}

.contact-container .ant-select,
.ant-select-item {
  width: 250px;
  margin-right: 24px !important;
  font-family: 'GlacialInDifference' !important;
  font-size: 18px !important;
}

.contact-container {
  margin: auto;
  /* width: 1140px; */
  padding-left: 25px!important;
  margin-bottom: 24px;
  margin-top: 24px;
}

.contact-name {
  font-weight: 700;
  font-style: normal;
  color: rgb(29, 76, 128) !important;
  text-decoration: none;
  margin-top: 12px;
  font-family: 'GlacialInDifference' !important;
  font-size: 18px;
  line-height: 28px;
}

.contact-name a {
  color: rgb(29, 76, 128) !important;
}

.contact-subtext {
  display: block;
  width: 100%;
}

.map-modal .ant-modal-body {
  height: 400px;
}

.contact-container .company-name {
  color: rgba(0, 0, 0, 0.85) !important;
  font-size: 18px !important;
  text-transform: capitalize;
  margin-bottom: 0px;
}

.contact-container .mobile {
  display: none;
}

@media (max-width: 480px) {
  .contact-container {
    width: 100% !important;
    padding: 20px !important;
    margin-bottom: 0px !important;
    padding-top: 0px !important;
  }
  .contact-container .mobile-margin-bottom-0 {
    margin-bottom: 8px 0px 0px 0px !important;
  }
  .contact-container .mobile-padding-0 .ant-card-body {
    padding: 0 !important;
  }
  .contact-container .mobile-padding-0 .mobile-margin-0 {
    margin-top: 0px;
  }
  .contact-container .sub-text {
    font-size: 17px;
  }
  .contact-hero-container {
    width: 100%;
  }
  .contact-container .customer-relations-card .ant-card-body {
    padding: 12px 0px 0px 0px;
  }
  .contact-container .customer-relations-card .margin-left-68 {
    margin-left: 0;
  }
  .contact-container .customer-relations-card .ant-card-body {
    text-align: center;
  }
  .contact-container .mobile-12 {
    margin-bottom: 0px !important;
  }

  .contact-container .desktop {
    display: none;
  }

  .contact-container .mobile {
    display: block;
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .contact-container .text-reset {
    font-size: medium !important;
  }
  .contact-container .start-quote span {
    font-size: 20px;
    width: 100%;
  }
  .contact-container .mobile-text {
    font-size: 18px !important;
  }
  .contact-container .mobile-sub-text {
    font-size: 17px !important;
  }
  .contact-container .start-quote {
    padding: 0;
  }
  .contact-container .below-button {
    margin-top: 8px;
  }
  .contact-container .above-button {
    margin-bottom: 8px;
  }
}

@media (min-width: 481px) and (max-width: 768px) {
  .contact-container {
    padding-left: 30px;
    padding-right: 30px;
    width: 100% !important;
  }
}

@media (min-width: 769px) and (max-width: 1025px) {
  .contact-container {
    padding-left: 30px !important;
    /* padding-right: 45px; */
    width: 100% !important;
  }
}

@media (min-width: 1200px) {
  .contact-container {
    padding-left: 32px !important;
  }
}